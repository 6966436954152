import React from 'react';

const Section = ({ title, content, subsections }) => {
  return (
    <div className="subsection">
      <h3 className="title" styles='color:white;'>{title}</h3>
      
      {/* If we have direct content for this main section, show it */}
      {content && (
        <p className="content-paragraph" style={{ whiteSpace: 'pre-line' }}>
          {content}
        </p>
      )}
      
      {/* If we have subsections, iterate over them */}
      {subsections && subsections.map((sub, index) => (
        <div key={index} style={{ marginLeft: '1.5rem', marginTop: '1rem' }}>
          <h4 className="subtitle" style={{ color: 'white' }}>{sub.subtitle}</h4>
          <p className="content-paragraph" style={{ whiteSpace: 'pre-line' }}>
            {sub.content}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Section;