import React from 'react';
import '../App.css';
import logo from '../assets/IOS logo (white).png';
import Navbar from '../components/sections/Navbar';
import Footer from '../components/sections/footer';
import Section from '../components/Section'; // The new Section component

function Privacy() {
  const sections = [
    // 1. Consent and Agreement
    {
      title: '1. Consent and Agreement',
      content: `By using TravelMe or clicking “I Agree” within the app:

1. You acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
2. If you are a parent or guardian, you confirm that you consent to your child (under the age of 13) using the TravelMe app in accordance with this Privacy Policy.
3. If you are under 13, you confirm that your parent or guardian has reviewed and agreed to this Privacy Policy on your behalf.

If you do not agree with any part of this Privacy Policy, please discontinue use of TravelMe immediately.`
    },

    // 2. Information Collection (Already has subsections)
    {
      title: '2. Information Collection',
      subsections: [
        {
          subtitle: '2.1 Personal Information',
          content: `User Details: We collect limited personal details such as your first and last name, email address, and app preferences.
Location Data: We may collect your device’s location information (GPS-based) when you allow access through your device settings.`
        },
        {
          subtitle: '2.2 Children Under 13',
          content: `Parental Consent: In compliance with the Children’s Online Privacy Protection Act (COPPA), we collect personal information from children under 13 only with verifiable parental consent. If we discover we have inadvertently collected data from a child under 13 without consent, we will promptly delete it.`
        },
        {
          subtitle: '2.3 User-Generated Content',
          content: `Text and Images: TravelMe allows users to create and share content (text and images). This content may be visible to other users depending on your app settings.
Metadata: When you upload images, we may receive metadata such as timestamps or geolocation data if embedded in the file (you can remove this data before uploading).`
        }
      ]
    },

    // 3. Information Use
    {
      title: '3. Information Use',
      subsections: [
        {
          subtitle: '3.1 Provide and Personalize Services',
          content: `Enhance in-app visuals, tailor recommendations, and offer interactive experiences (e.g., leaderboards, achievements).`
        },
        {
          subtitle: '3.2 Facilitate Sharing',
          content: `Identify you to other users when using premium or sharing features.`
        },
        {
          subtitle: '3.3 Moderate User-Generated Content',
          content: `We employ AI-driven tools to detect and restrict explicit, harmful, or otherwise inappropriate content (text or images) before it is displayed publicly.`
        }
      ]
    },

    // 4. Information Sharing and Disclosure
    {
      title: '4. Information Sharing and Disclosure',
      subsections: [
        {
          subtitle: '4.1 No Unauthorized Sharing',
          content: `We do not sell or rent your personal data to third parties.`
        },
        {
          subtitle: '4.2 Service Providers',
          content: `We may share non-identifying, aggregated information with third-party service providers (e.g., cloud hosting, AI moderation services) to help deliver and improve TravelMe.`
        },
        {
          subtitle: '4.3 Legal Requirements',
          content: `We may disclose user information if required by law, regulation, or court order, or if necessary to protect the rights, property, or safety of TravelMe, our users, or others.`
        }
      ]
    },

    // 5. App Information and User Safety
    {
      title: '5. App Information and User Safety',
      subsections: [
        {
          subtitle: '5.1 General Guidelines',
          content: `The safety ratings, recommendations, and information provided in this app are intended as general guidelines only. While we strive for accuracy, conditions can change rapidly, and our information may not be complete or up to date.`
        },
        {
          subtitle: '5.2 Exercise Caution',
          content: `Users are encouraged to exercise caution and verify details independently, especially when traveling.`
        },
        {
          subtitle: '5.3 Seek Reliable Sources',
          content: `Seek advice from local authorities or other reliable sources before traveling.`
        },
        {
          subtitle: '5.4 Liability Disclaimer',
          content: `TravelMe assumes no liability for any harm, loss, or damages resulting from reliance on the information provided.`
        }
      ]
    },

    // 6. User-Generated Content Guidelines (Already has subsections)
    {
      title: '6. User-Generated Content Guidelines',
      subsections: [
        {
          subtitle: '6.1 Content Standards:',
          content: `Users must ensure all text, images, and other forms of content comply with our community standards. Explicit or harmful content is strictly prohibited.`
        },
        {
          subtitle: '6.2 AI Moderation:',
          content: `We use AI-based tools to evaluate and filter out explicit or inappropriate content before it appears publicly. While we strive for accuracy, no system is perfect, and some inappropriate content may appear before removal.`
        },
        {
          subtitle: '6.3 User Responsibility:',
          content: `You are solely responsible for the content you post. If you encounter any content that violates our guidelines, please report it. TravelMe reserves the right to remove content or suspend accounts that pose risks to other users.`
        }
      ]
    },

    // 7. User Rights and Choices
    {
      title: '7. User Rights and Choices',
      subsections: [
        {
          subtitle: '7.1 Account Management',
          content: `You can update or remove your personal information and account through the in-app settings.`
        },
        {
          subtitle: '7.2 UGC Deletion',
          content: `You may delete or request deletion of your user-generated content at any time. Backups or cached copies may still exist for a short period.`
        },
        {
          subtitle: '7.3 Parental Controls',
          content: `Parents or guardians of users under 13 can review, edit, or request deletion of their child’s information, including content, by contacting us.`
        }
      ]
    },

    // 8. Data Security
    {
      title: '8. Data Security',
      subsections: [
        {
          subtitle: '8.1 Secure Storage',
          content: `Your personal data and user-generated content are securely stored on our cloud servers, accessible only by authorized personnel.`
        },
        {
          subtitle: '8.2 Privacy Controls',
          content: `Your journey data is private and accessible only by you. However, certain features—such as posting reviews, ratings, or maintaining a public profile (including profile images)—are inherently visible to other users within the TravelMe app. By using these features, you acknowledge that your content and associated profile details will be publicly viewable. If you do not wish for specific content or profile details to be visible to others, please refrain from using these public features.`
        },
        {
          subtitle: '8.3 Retention',
          content: `We retain your information and content as long as your account is active or needed for service provision. We may keep data for a reasonable period to comply with legal obligations or resolve disputes.`
        }
      ]
    },

    // 9. Children’s Privacy
    {
      title: '9. Children’s Privacy',
      content: `TravelMe is committed to protecting the privacy of children. We do not knowingly collect personal information from children under 13 without parental consent. If you believe we have collected personal information from a child under 13 without consent, please contact us immediately so we can delete it.`
    },

    // 10. Changes to This Privacy Policy
    {
      title: '10. Changes to This Privacy Policy',
      content: `TravelMe may update this Privacy Policy to reflect changes in our practices or for operational, legal, or regulatory reasons. We will notify you of material changes by posting the new Privacy Policy on this page and updating the “Last updated” date. Your continued use of TravelMe after such updates constitutes acceptance of the revised Privacy Policy.`
    },

    // 11. Contact Us
    {
      title: '11. Contact Us',
      content: `If you have any questions or concerns about this Privacy Policy, or if you wish to make inquiries regarding your child’s data, please contact us at:
contact@travelmeapp.co.uk`
    }
  ];

  return (
    <div className="container">
      <Navbar />
      <div className="full-container">
        <div className="frontpage">
          <h1 className="title">Privacy Policy</h1>
          <h2 className="subtitle">Privacy Policy for TravelMe, Last updated: 31/01/2025</h2>
          <p className="content-paragraph">
            Welcome to TravelMe, the innovative app that revolutionizes multi-destination travel
            planning. Our platform offers an intuitive way to manage itineraries, track expenses, and
            share travel experiences with others. We also incorporate engaging features such as
            global travel progression statistics, achievements, rankings, and map completion to add a
            competitive edge to your travels. TravelMe strives to remain child-friendly, providing
            content suitable for all ages.
            <br />
            <br />
            This Privacy Policy explains how we collect, use, disclose, and safeguard your
            information when you use our application. Please read this document carefully.
          </p>
        </div>
        <div style={{ paddingTop: 40 }}>
          {sections.map((section, index) => (
            <Section
              key={index}
              title={section.title}
              content={section.content}
              subsections={section.subsections} // pass subsections if they exist
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
