import React, { useState } from 'react';
import Navbar from '../components/sections/Navbar';
import './joinjourney.css';
import NewYorkImage from '../assets/carousel/new-york.jpg';
import NorwayImage from '../assets/carousel/norway.jpg';
import SantoriniImage from '../assets/carousel/santorini.jpeg';
import AutoScrollImages from '../components/AutoScrollImages';
import PexelsCarloObrienImage from '../assets/carousel/pexels-carlo-obrien-402486657-15301144.jpg';
import PexelsIliaBronskiyImage from '../assets/carousel/pexels-ilia-bronskiy-1137858493-20888052.jpg';
import PexelsHolodnaImage from '../assets/carousel/pexels-holodna-17400602.jpg';
import PexelsAlecDouaImage from '../assets/carousel/pexels-alecdoua-17645285.jpg';
import PexelsKsuEliStudioImage from '../assets/carousel/pexels-ksu-eli-studio-78564297-8681433.jpg';
import PexelsAlinaSkazkaImage from '../assets/carousel/pexels-alinaskazka-25539612.jpg';
import LeftPathComponent from '../assets/svgs/leftPathComponent';
import { Helmet } from 'react-helmet';

function JoinJourney() {
  const [buttonOpacity, setButtonOpacity] = useState(1);
  const currentUrl = window.location.href;
  const deepLinkUrl = currentUrl.replace(/^https?:\/\/[^/]+/, 'travelme:/');
  const [isPressed, setIsPressed] = useState(false);
  const imagePaths = [
    NewYorkImage,
    NorwayImage,
    SantoriniImage,
    PexelsCarloObrienImage,
    PexelsIliaBronskiyImage,
    PexelsHolodnaImage,
    PexelsAlecDouaImage,
    PexelsKsuEliStudioImage,
    PexelsAlinaSkazkaImage
  ];

  // Handle deep link with App Store fallback
  const handleAcceptInvite = () => {
    setButtonOpacity(0.5); 
    setTimeout(() => {
      setButtonOpacity(1); // Restore opacity after 300ms
    }, 300);
    
    // Set a flag to track whether the user is prompted to open the app
    let deepLinkAttempted = true;
    let isAppOpened = false;
    setIsPressed(true)
    setTimeout(() => {
      setIsPressed(false)
    }, 200);
    // Try to open the app using the deep link
    window.location.href = deepLinkUrl;
  
    // Set up a listener for visibility change to track if the user interacts with the app open dialog
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // The document becomes hidden, likely because the user responded to the deep link popup
        isAppOpened = true;
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    // Set a timeout to redirect to the App Store if deep link doesn't work
    setTimeout(() => {
      if (deepLinkAttempted && !isAppOpened) {
        console.log("here")
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const appStoreUrl = isIOS 
          ? 'itms-apps://itunes.apple.com/app/idYOUR_APP_ID'  // iOS App Store URL
          : 'https://play.google.com/store/apps/details?id=YOUR_APP_PACKAGE';  // Google Play Store URL
  
        // Redirect to the App Store or Play Store
        window.location.href = appStoreUrl;
      }
      // Clean up the event listener after the timeout
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, 3000);  // Wait for 3 seconds before redirecting to the app store
  
    // Clear the deep link flag if the user navigates away (which indicates the app opened successfully)
    window.addEventListener('blur', () => {
      deepLinkAttempted = false;
    });
  };

  return (
    <div className='jj-container' style={{ position: "relative" }}>
      <Helmet>       
      <meta property="al:ios:url" content={deepLinkUrl} />
      <meta property="al:ios:app_store_id" content="YOUR_APP_ID" />
      <meta property="al:ios:app_name" content="TravelMe" />
      <meta property="al:web:should_fallback" content="false" />
        {/* Fallback if the app doesn't open */}
        <meta http-equiv="refresh" content={`5;url=${currentUrl}`} />
        {/* Meta refresh to open the custom app scheme */}
        <meta http-equiv="refresh" content={`1;url=${deepLinkUrl}`} />

      </Helmet>
      <Navbar />
      <div className='path-bg'>
        <LeftPathComponent />
      </div>
      <div className='full-container-joinjourney '>
        <AutoScrollImages photos={imagePaths} />

        <button 
          onClick={handleAcceptInvite} 
          className='accept-invite-button'
          style={{ opacity: buttonOpacity }}
        >
          <p className='title'>Accept Invite</p>
        </button>
      </div>
      <div className='textTop'>
        <h1 className="title">
          You're nearly there!
        </h1>
      </div>

    </div>
  );
}

export default JoinJourney;
